import { toString } from 'lodash';
import { SearchResultPageContext } from 'pages/[ket-qua-tim-kiem]';
import { useContext, useEffect, useRef } from 'react';

const SeoContent = () => {
  const { slugify } = useContext(SearchResultPageContext).pageData ?? {};
  const { seoContent, title } = slugify ?? {};
  const paragraphRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (paragraphRef.current) {
      paragraphRef.current.innerHTML = toString(seoContent);
    }
  }, [seoContent]);

  return (
    <div id='seo-content' className='flex flex-col space-y-[24px]'>
      <h2 className='text-[18px] font-[600] leading-[23px]'>{`Giới thiệu tổng quan về ${title}`}</h2>
      <p ref={paragraphRef} />
    </div>
  );
};

export default SeoContent;
