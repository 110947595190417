import { IconClose, IconDirectionDown, IconUndo } from 'components/icons';
import { useListProvinceContext } from 'components/providers/list-province-provider';
import { CommonAddress } from 'graphql/main/queries';
import { filter, find, includes, isEmpty, isEqual, isUndefined, map } from 'lodash';
import { useEffect, useState } from 'react';
import { removeVietnameseAccents } from 'utils';

interface Props {
  showing?: boolean;
  value?: CommonAddress;
  onToggle?: (popover: string) => void;
  onChange?: (value?: CommonAddress) => void;
}

const ProvincePopover = ({ showing, value, onToggle, onChange }: Props) => {
  const [search, setSearch] = useState('');
  const [privateProvinceValue, setPrivateProvinceValue] = useState(value);
  const { data: provinces } = useListProvinceContext();
  const [privateOptions, setPrivateOptions] = useState(provinces);
  const label = find(provinces, (option) => isEqual(option.id, value?.id))?.name;

  const select = (_value: CommonAddress) => {
    setSearch('');
    onToggle?.('province');
    if (!isEqual(value, _value)) {
      onChange?.(_value);
    }
  };
  const reset = () => {
    setSearch('');
    if (!isUndefined(value)) {
      onChange?.(undefined);
    }
  };

  useEffect(() => {
    setPrivateOptions(
      filter(provinces, (option) =>
        includes(removeVietnameseAccents(option.name), removeVietnameseAccents(search)),
      ),
    );
  }, [search]);
  useEffect(() => {
    setPrivateProvinceValue(value);
  }, [value]);
  useEffect(() => {
    setSearch('');
  }, [showing]);

  return (
    <div id='province-popover' className='relative flex w-full min-w-0 flex-col space-y-[8px]'>
      <button
        type='button'
        className={`flex h-[40px] items-center rounded-[8px] border bg-paper p-[8px] transition-all duration-[200ms] ease-in-out disabled:cursor-not-allowed disabled:opacity-50 ${
          showing ? 'border-primary' : 'border-stroke'
        }`}
        onClick={() => onToggle?.('province')}
      >
        <span className={`w-full truncate text-left ${label ? '' : 'text-text2'}`}>
          {label ?? 'Tỉnh/ Thành phố'}
        </span>
        <div className='broder-stroke h-full border-l pl-[8px]'>
          <IconDirectionDown className='min-h-[24px] min-w-[24px] text-text2' />
        </div>
      </button>
      {showing && (
        <div className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[4px]'>
          <div className='w-[312px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
            <div className='group mx-[16px] flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
              <input
                type='text'
                className='w-full bg-transparent p-[12px] placeholder-text2'
                placeholder='Nhập từ khoá tìm kiếm'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              {Boolean(search) && (
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={() => setSearch('')}
                >
                  <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                </button>
              )}
            </div>
            {isEmpty(privateOptions) ? (
              <span className='block h-full px-[16px] italic text-text2'>Không có tuỳ chọn</span>
            ) : (
              <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
                {map(privateOptions, (option, optionIndex) => (
                  <li key={`${option.id}-${optionIndex}`}>
                    <button
                      type='button'
                      className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                      onClick={() => {
                        select(option);
                      }}
                    >
                      <div
                        className={`min-h-[20px] min-w-[20px] rounded-full border ${
                          isEqual(privateProvinceValue?.id, option.id)
                            ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                            : 'border-stroke'
                        }`}
                      />
                      <span className='line-clamp-1'>{option.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
            <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
              <button
                type='button'
                className='flex items-center justify-center space-x-[6px]'
                onClick={reset}
              >
                <IconUndo className='text-text2' />
                <span>Đặt lại</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProvincePopover;
