import { IconUndo } from 'components/icons';
import { SearchForm1 } from 'forms';
import { isEmpty, isUndefined } from 'lodash';
import { Fragment } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import AddressPopover from './address-popover';
import AreaPopover from './area-popover';
import MoreFilterPopover from './more-filter-popover';
import PricePopover from './price-popover';
import TypePopover from './type-popover';

interface Props {
  onSubmit?: () => void;
}

const FilterList = ({ onSubmit }: Props) => {
  const form = useFormContext<SearchForm1>();
  const typeWatch = useWatch({ control: form.control, name: 'type' });
  const provinceWatch = useWatch({ control: form.control, name: 'province' });
  const districtWatch = useWatch({ control: form.control, name: 'district' });
  const wardWatch = useWatch({ control: form.control, name: 'ward' });
  const streetWatch = useWatch({ control: form.control, name: 'type' });
  const priceWatch = useWatch({ control: form.control, name: 'price' });
  const areaWatch = useWatch({ control: form.control, name: 'area' });
  const hasAlleyWatch = useWatch({ control: form.control, name: 'hasAlley' });
  const widthWatch = useWatch({ control: form.control, name: 'width' });
  const lengthWatch = useWatch({ control: form.control, name: 'length' });
  const directionWatch = useWatch({ control: form.control, name: 'direction' });
  const bedroomCountWatch = useWatch({ control: form.control, name: 'bedroomCount' });
  const toiletCountWatch = useWatch({ control: form.control, name: 'toiletCount' });
  const floorCountWatch = useWatch({ control: form.control, name: 'floorCount' });
  const postTypeWatch = useWatch({ control: form.control, name: 'postType' });
  const postSourceWatch = useWatch({ control: form.control, name: 'postSource' });
  const pageNoWatch = useWatch({ control: form.control, name: 'pageNo' });
  const parcelWatch = useWatch({ control: form.control, name: 'parcel' });

  const reset = () => {
    if (
      !isUndefined(typeWatch) ||
      !isUndefined(provinceWatch) ||
      !isUndefined(districtWatch) ||
      !isUndefined(wardWatch) ||
      !isUndefined(streetWatch) ||
      !isUndefined(priceWatch) ||
      !isUndefined(areaWatch) ||
      !isUndefined(hasAlleyWatch) ||
      !isUndefined(widthWatch) ||
      !isUndefined(lengthWatch) ||
      !isUndefined(directionWatch) ||
      !isUndefined(bedroomCountWatch) ||
      !isUndefined(toiletCountWatch) ||
      !isUndefined(floorCountWatch) ||
      !isUndefined(postTypeWatch) ||
      !isUndefined(postSourceWatch) ||
      !isEmpty(pageNoWatch) ||
      !isEmpty(parcelWatch)
    ) {
      form.setValue('type', undefined);
      form.setValue('province', undefined);
      form.setValue('district', undefined);
      form.setValue('ward', undefined);
      form.setValue('street', undefined);
      form.setValue('price', undefined);
      form.setValue('area', undefined);
      form.setValue('hasAlley', undefined);
      form.setValue('width', undefined);
      form.setValue('length', undefined);
      form.setValue('direction', undefined);
      form.setValue('bedroomCount', undefined);
      form.setValue('toiletCount', undefined);
      form.setValue('floorCount', undefined);
      form.setValue('postType', undefined);
      form.setValue('postSource', undefined);
      form.setValue('pageNo', '');
      form.setValue('parcel', '');
      onSubmit?.();
    }
  };
  const submit = () => {
    onSubmit?.();
  };

  return (
    <div id='filter-list' className='flex h-[36px] items-center space-x-[16px]'>
      <TypePopover onSubmit={submit} />
      <div className='h-full w-[1px] bg-stroke py-[4px]' />
      <AddressPopover onSubmit={submit} />
      <div className='h-full w-[1px] bg-stroke py-[4px]' />
      <AreaPopover onSubmit={submit} />
      <div className='h-full w-[1px] bg-stroke py-[4px]' />
      <PricePopover onSubmit={submit} />
      <div className='h-full w-[1px] bg-stroke py-[4px]' />
      <MoreFilterPopover onSubmit={submit} />
      <Fragment>
        <div className='h-full w-[1px] bg-stroke' />
        <button
          type='button'
          className='group flex h-full items-center space-x-[8px] transition duration-[200ms] ease-in-out hover:text-primary'
          onClick={reset}
        >
          <IconUndo className='text-text2 duration-[200ms] group-hover:text-primary' />
          <span className='hidden truncate xl:inline'>Đặt lại</span>
        </button>
      </Fragment>
    </div>
  );
};

export default FilterList;
