import { IconLocationOutline } from 'components/icons';
import { StatisticRealEstate, StatisticRealEstateByProvince } from 'graphql/main/queries';
import { isEmpty, isNil, map } from 'lodash';
import Link from 'next/link';
import { SearchResultPageContext } from 'pages/[ket-qua-tim-kiem]';
import { useContext } from 'react';

const NeighborhoodCardList = () => {
  const { nearRealEstates } = useContext(SearchResultPageContext).pageData ?? {};

  return isEmpty(nearRealEstates) ? null : (
    <div
      id='neighborhood-card-list'
      className='container mx-auto min-w-[1052px] px-[54px] screen-1140:px-[98px] xl:px-[48px] screen-1366:px-[83px]'
    >
      <div className='flex flex-col space-y-[24px] rounded-[16px] bg-paper py-[16px] shadow-1'>
        <div className='flex items-center pr-[16px]'>
          <div className='flex items-center space-x-[8px]'>
            <div className='rounded-r-[50px] bg-primary px-[23px] py-[7px]'>
              <IconLocationOutline className='min-h-[24px] min-w-[24px] text-paper' />
            </div>
            <h2 className='text-[20px] font-[600] leading-[23px]'>Các bất động sản lân cận</h2>
          </div>
        </div>
        <div className='my-[-8px] flex flex-wrap px-[24px]'>
          {map(nearRealEstates, (nearRealEstate, nearRealEstateIndex) => (
            <div
              key={`${
                (nearRealEstate as StatisticRealEstateByProvince).provinceId ||
                (nearRealEstate as StatisticRealEstate).districtId
              }-${nearRealEstateIndex}`}
              className='w-1/4 py-[8px]'
            >
              <Link
                href={
                  isNil(
                    (nearRealEstate as StatisticRealEstateByProvince).slug ||
                      (nearRealEstate as StatisticRealEstate).slug,
                  )
                    ? '#'
                    : `/${
                        (nearRealEstate as StatisticRealEstateByProvince).slug ||
                        (nearRealEstate as StatisticRealEstate).slug
                      }`
                }
              >
                <span className='text-primary'>
                  {(nearRealEstate as StatisticRealEstateByProvince).provinceName ||
                    (nearRealEstate as StatisticRealEstate).districtName}{' '}
                  (
                  {(nearRealEstate as StatisticRealEstateByProvince).total ||
                    (nearRealEstate as StatisticRealEstate).total}
                  )
                </span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NeighborhoodCardList;
