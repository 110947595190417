import DemandTypeButton from 'components/desktop/search-result/search-tool/demand-type-button';
import FilterList from 'components/desktop/search-result/search-tool/filter-list';
import SearchInput from 'components/desktop/search-result/search-tool/search-input';
import { useListProvinceContext } from 'components/providers/list-province-provider';
import { SearchForm1, searchFormDefaultValues1 } from 'forms';
import {
  Direction,
  RealEstateTypeEnum,
  SLUGERY,
  SlugeryData,
  SlugeryVariables,
} from 'graphql/main/queries';
import {
  GET_LIST_DISTRICTS_OF_PROVINCE,
  GET_LIST_STREETS_OF_DISTRICT,
  GET_LIST_WARDS_OF_DISTRICT,
  GetListDistrictsOfProvinceData,
  GetListDistrictsOfProvinceVariables,
  GetListStreetsOfDistrictData,
  GetListStreetsOfDistrictVariables,
  GetListWardsOfDistrictData,
  GetListWardsOfDistrictVariables,
} from 'graphql/map/queries';
import { useFlexLazyQuery } from 'hooks';
import {
  find,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isString,
  isUndefined,
  join,
  max,
  min,
  toNumber,
} from 'lodash';
import { useRouter } from 'next/router';
import { SearchResultPageContext } from 'pages/[ket-qua-tim-kiem]';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const SearchTool = () => {
  const { slugify } = useContext(SearchResultPageContext).pageData ?? {};
  const { filters } = slugify ?? {};
  const { typeOfDemand, realEstateType, streetId, wardId, districtId, provinceId, area, price } =
    filters as any;
  const router = useRouter();
  const {
    search: searchQuery,
    sort: sortQuery,
    areaFrom: areaFromQuery,
    areaTo: areaToQuery,
    priceFrom: priceFromQuery,
    priceTo: priceToQuery,
    hasAlley: hasAlleyQuery,
    widthFrom: widthFromQuery,
    widthTo: widthToQuery,
    lengthFrom: lengthFromQuery,
    lengthTo: lengthToQuery,
    bedroomCountFrom: bedroomCountFromQuery,
    bedroomCountTo: bedroomCountToQuery,
    toiletCountFrom: toiletCountFromQuery,
    toiletCountTo: toiletCountToQuery,
    floorCountFrom: floorCountFromQuery,
    floorCountTo: floorCountToQuery,
    direction: directionQuery,
    postType: postTypeQuery,
    isAuthorizedRe: isAuthorizedReQuery,
    isForeclosure: isForeclosureQuery,
    isLeadRE: isLeadREQuery,
    pageNo: pageNoQuery,
    parcel: parcelQuery,
  } = router.query;
  const form = useForm<SearchForm1>({
    defaultValues: searchFormDefaultValues1,
  });
  const [slugery] = useFlexLazyQuery<SlugeryData, SlugeryVariables>('main', SLUGERY);
  const { data: provinces } = useListProvinceContext();
  const [getListDistrictsOfProvince] = useFlexLazyQuery<
    GetListDistrictsOfProvinceData,
    GetListDistrictsOfProvinceVariables
  >('map', GET_LIST_DISTRICTS_OF_PROVINCE);
  const [getListWardsOfDistrict] = useFlexLazyQuery<
    GetListWardsOfDistrictData,
    GetListWardsOfDistrictVariables
  >('map', GET_LIST_WARDS_OF_DISTRICT);
  const [getListStreetsOfDistrict] = useFlexLazyQuery<
    GetListStreetsOfDistrictData,
    GetListStreetsOfDistrictVariables
  >('map', GET_LIST_STREETS_OF_DISTRICT);

  const submit = async () => {
    const data = form.getValues();
    const slugeryData = await slugery({
      variables: {
        search: data.search,
        demandType: data.demandType,
        type: data.type,
        address:
          data.province || data.district || data.ward || data.street
            ? {
                province: data.province,
                district: data.district,
                ward: data.ward,
                street: data.street,
              }
            : undefined,
        area:
          data.area?.from || data.area?.to
            ? isUndefined(data.area?.from)
              ? {
                  from: 0,
                  to: data.area?.to,
                }
              : isNil(data.area?.to)
              ? {
                  from: data.area?.from,
                }
              : {
                  from: min([data.area?.from, data.area?.to]),
                  to: max([data.area?.from, data.area?.to]),
                }
            : undefined,
        price:
          data.price?.from || data.price?.to
            ? isUndefined(data.price?.from)
              ? {
                  from: 0,
                  to: data.price?.to,
                }
              : isNil(data.price?.to)
              ? {
                  from: data.price?.from,
                }
              : {
                  from: min([data.price?.from, data.price?.to]),
                  to: max([data.price?.from, data.price?.to]),
                }
            : undefined,
        hasAlley: isEmpty(data.hasAlley) ? undefined : isEqual(data.hasAlley, 'true'),
        width:
          data.width?.from || data.width?.to
            ? isUndefined(data.width?.from)
              ? {
                  from: 0,
                  to: data.width?.to,
                }
              : isNil(data.width?.to)
              ? {
                  from: data.width?.from,
                }
              : {
                  from: min([data.width?.from, data.width?.to]),
                  to: max([data.width?.from, data.width?.to]),
                }
            : undefined,
        length:
          data.length?.from || data.length?.to
            ? isUndefined(data.length?.from)
              ? {
                  from: 0,
                  to: data.length?.to,
                }
              : isNil(data.length?.to)
              ? {
                  from: data.length?.from,
                }
              : {
                  from: min([data.length?.from, data.length?.to]),
                  to: max([data.length?.from, data.length?.to]),
                }
            : undefined,
        direction: isEmpty(data.direction) ? undefined : data.direction,
        bedroomCount:
          data.bedroomCount?.from || data.bedroomCount?.to
            ? isUndefined(data.bedroomCount?.from)
              ? {
                  from: 0,
                  to: data.bedroomCount?.to,
                }
              : isNil(data.bedroomCount?.to)
              ? {
                  from: data.bedroomCount?.from,
                }
              : {
                  from: min([data.bedroomCount?.from, data.bedroomCount?.to]),
                  to: max([data.bedroomCount?.from, data.bedroomCount?.to]),
                }
            : undefined,
        toiletCount:
          data.toiletCount?.from || data.toiletCount?.to
            ? isUndefined(data.toiletCount?.from)
              ? {
                  from: 0,
                  to: data.toiletCount?.to,
                }
              : isNil(data.toiletCount?.to)
              ? {
                  from: data.toiletCount?.from,
                }
              : {
                  from: min([data.toiletCount?.from, data.toiletCount?.to]),
                  to: max([data.toiletCount?.from, data.toiletCount?.to]),
                }
            : undefined,
        floorCount:
          data.floorCount?.from || data.floorCount?.to
            ? isUndefined(data.floorCount?.from)
              ? {
                  from: 0,
                  to: data.floorCount?.to,
                }
              : isNil(data.floorCount?.to)
              ? {
                  from: data.floorCount?.from,
                }
              : {
                  from: min([data.floorCount?.from, data.floorCount?.to]),
                  to: max([data.floorCount?.from, data.floorCount?.to]),
                }
            : undefined,
        postType: isEmpty(data.postType) ? undefined : toNumber(data.postType),
        ...(isString(data.postSource)
          ? isEmpty(data.postSource)
            ? {}
            : isEqual(data.postSource, 'undefined')
            ? { isAuthorizedRe: false, isForeclosure: false, isLeadRE: false }
            : { [data.postSource]: true }
          : {}),
        pageNo: data.pageNo,
        parcel: data.parcel,
      },
    });
    if (slugeryData.data?.slugery?.slug) {
      const parsedUrl = queryString.parseUrl(slugeryData.data.slugery.slug);
      router.push({
        pathname: parsedUrl.url,
        query: isEmpty(sortQuery)
          ? queryString.stringify(parsedUrl.query)
          : join([queryString.stringify(parsedUrl.query), `&sort=${sortQuery}`], ''),
      });
    }
  };

  useEffect(() => {
    form.setValue('search', isString(searchQuery) ? searchQuery : undefined);
  }, [searchQuery]);
  useEffect(() => {
    form.setValue('demandType', isEqual(typeOfDemand, 'ForRent') ? 'rent' : 'sell');
  }, [typeOfDemand]);
  useEffect(() => {
    form.setValue(
      'type',
      isArray(realEstateType)
        ? [realEstateType[0] as RealEstateTypeEnum]
        : isString(realEstateType)
        ? [realEstateType as RealEstateTypeEnum]
        : undefined,
    );
  }, [realEstateType]);
  useEffect(() => {
    (async () => {
      if (provinceId) {
        form.setValue(
          'province',
          find(provinces, (province) => isEqual(province.id, provinceId)),
        );
        const getListDistrictsOfProvinceData = await getListDistrictsOfProvince({
          variables: {
            provinceId,
          },
        });
        if (districtId) {
          const { districts } =
            getListDistrictsOfProvinceData.data?.getListDistrictsOfProvince ?? {};
          form.setValue(
            'district',
            find(districts, (district) => isEqual(district.id, districtId)),
          );
          const getListWardsOfDistrictData = await getListWardsOfDistrict({
            variables: {
              districtId,
            },
          });
          const { wards } = getListWardsOfDistrictData.data?.getListWardsOfDistrict ?? {};
          form.setValue(
            'ward',
            find(wards, (ward) => isEqual(ward.id, wardId)),
          );
          const getListStreetsOfDistrictData = await getListStreetsOfDistrict({
            variables: {
              districtId,
            },
          });
          const { streets } = getListStreetsOfDistrictData.data?.getListStreetsOfDistrict ?? {};
          form.setValue(
            'street',
            find(streets, (street) => isEqual(street.id, streetId)),
          );
        }
      }
    })();
  }, [provinces, provinceId, districtId, wardId, streetId]);
  useEffect(() => {
    const hasAreaFrom =
      isString(areaFromQuery) &&
      !isNaN(toNumber(areaFromQuery)) &&
      isNumber(toNumber(areaFromQuery));
    const hasAreaTo =
      isString(areaToQuery) && !isNaN(toNumber(areaToQuery)) && isNumber(toNumber(areaToQuery));
    form.setValue(
      'area',
      area
        ? { from: area.from, to: area.to }
        : hasAreaFrom || hasAreaTo
        ? !hasAreaFrom
          ? { from: 0, to: toNumber(areaToQuery) }
          : !hasAreaTo
          ? { from: toNumber(areaFromQuery), to: null }
          : { from: toNumber(areaFromQuery), to: toNumber(areaToQuery) }
        : undefined,
    );
  }, [area, areaFromQuery, areaToQuery]);
  useEffect(() => {
    const hasPriceFrom =
      isString(priceFromQuery) &&
      !isNaN(toNumber(priceFromQuery)) &&
      isNumber(toNumber(priceFromQuery));
    const hasPriceTo =
      isString(priceToQuery) && !isNaN(toNumber(priceToQuery)) && isNumber(toNumber(priceToQuery));
    form.setValue(
      'price',
      price
        ? { from: price.from, to: price.to }
        : hasPriceFrom || hasPriceTo
        ? !hasPriceFrom
          ? { from: 0, to: toNumber(priceToQuery) }
          : !hasPriceTo
          ? { from: toNumber(priceFromQuery), to: null }
          : { from: toNumber(priceFromQuery), to: toNumber(priceToQuery) }
        : undefined,
    );
  }, [price, priceFromQuery, priceToQuery]);
  useEffect(() => {
    form.setValue('hasAlley', isString(hasAlleyQuery) ? hasAlleyQuery : undefined);
  }, [hasAlleyQuery]);
  useEffect(() => {
    const hasWidthFrom =
      isString(widthFromQuery) &&
      !isNaN(toNumber(widthFromQuery)) &&
      isNumber(toNumber(widthFromQuery));
    const hasWidthTo =
      isString(widthToQuery) && !isNaN(toNumber(widthToQuery)) && isNumber(toNumber(widthToQuery));
    form.setValue(
      'width',
      hasWidthFrom || hasWidthTo
        ? !hasWidthFrom
          ? { from: 0, to: toNumber(widthToQuery) }
          : !hasWidthTo
          ? { from: toNumber(widthFromQuery), to: null }
          : { from: toNumber(widthFromQuery), to: toNumber(widthToQuery) }
        : undefined,
    );
  }, [widthFromQuery, widthToQuery]);
  useEffect(() => {
    const hasLengthFrom =
      isString(lengthFromQuery) &&
      !isNaN(toNumber(lengthFromQuery)) &&
      isNumber(toNumber(lengthFromQuery));
    const hasLengthTo =
      isString(lengthToQuery) &&
      !isNaN(toNumber(lengthToQuery)) &&
      isNumber(toNumber(lengthToQuery));
    form.setValue(
      'length',
      hasLengthFrom || hasLengthTo
        ? !hasLengthFrom
          ? { from: 0, to: toNumber(lengthToQuery) }
          : !hasLengthTo
          ? { from: toNumber(lengthFromQuery), to: null }
          : { from: toNumber(lengthFromQuery), to: toNumber(lengthToQuery) }
        : undefined,
    );
  }, [lengthFromQuery, lengthToQuery]);
  useEffect(() => {
    const hasBedroomCountFrom =
      isString(bedroomCountFromQuery) &&
      !isNaN(toNumber(bedroomCountFromQuery)) &&
      isNumber(toNumber(bedroomCountFromQuery));
    const hasBedroomCountTo =
      isString(bedroomCountToQuery) &&
      !isNaN(toNumber(bedroomCountToQuery)) &&
      isNumber(toNumber(bedroomCountToQuery));
    form.setValue(
      'bedroomCount',
      hasBedroomCountFrom || hasBedroomCountTo
        ? !hasBedroomCountFrom
          ? { from: 0, to: toNumber(bedroomCountToQuery) }
          : !hasBedroomCountTo
          ? { from: toNumber(bedroomCountFromQuery), to: null }
          : {
              from: toNumber(bedroomCountFromQuery),
              to: toNumber(bedroomCountToQuery),
            }
        : undefined,
    );
  }, [bedroomCountFromQuery, bedroomCountToQuery]);
  useEffect(() => {
    const hasToiletCountFrom =
      isString(toiletCountFromQuery) &&
      !isNaN(toNumber(toiletCountFromQuery)) &&
      isNumber(toNumber(toiletCountFromQuery));
    const hasToiletCountTo =
      isString(toiletCountToQuery) &&
      !isNaN(toNumber(toiletCountToQuery)) &&
      isNumber(toNumber(toiletCountToQuery));
    form.setValue(
      'toiletCount',
      hasToiletCountFrom || hasToiletCountTo
        ? !hasToiletCountFrom
          ? { from: 0, to: toNumber(toiletCountToQuery) }
          : !hasToiletCountTo
          ? { from: toNumber(toiletCountFromQuery), to: null }
          : {
              from: toNumber(toiletCountFromQuery),
              to: toNumber(toiletCountToQuery),
            }
        : undefined,
    );
  }, [toiletCountFromQuery, toiletCountToQuery]);
  useEffect(() => {
    const hasFloorCountFrom =
      isString(floorCountFromQuery) &&
      !isNaN(toNumber(floorCountFromQuery)) &&
      isNumber(toNumber(floorCountFromQuery));
    const hasFloorCountTo =
      isString(floorCountToQuery) &&
      !isNaN(toNumber(floorCountToQuery)) &&
      isNumber(toNumber(floorCountToQuery));
    form.setValue(
      'floorCount',
      hasFloorCountFrom || hasFloorCountTo
        ? !hasFloorCountFrom
          ? { from: 0, to: toNumber(floorCountToQuery) }
          : !hasFloorCountTo
          ? { from: toNumber(floorCountFromQuery), to: null }
          : {
              from: toNumber(floorCountFromQuery),
              to: toNumber(floorCountToQuery),
            }
        : undefined,
    );
  }, [floorCountFromQuery, floorCountToQuery]);
  useEffect(() => {
    form.setValue(
      'direction',
      isString(directionQuery) ? (directionQuery as Direction) : undefined,
    );
  }, [directionQuery]);
  useEffect(() => {
    form.setValue('postType', isString(postTypeQuery) ? postTypeQuery : undefined);
  }, [postTypeQuery]);
  useEffect(() => {
    form.setValue(
      'postSource',
      isString(isAuthorizedReQuery) && isEqual(isAuthorizedReQuery, 'true')
        ? 'isAuthorizedRe'
        : isString(isForeclosureQuery) && isEqual(isForeclosureQuery, 'true')
        ? 'isForeclosure'
        : isString(isLeadREQuery) && isEqual(isLeadREQuery, 'true')
        ? 'isLeadRE'
        : undefined,
    );
  }, [isAuthorizedReQuery, isForeclosureQuery, isLeadREQuery]);
  useEffect(() => {
    form.setValue('pageNo', isString(pageNoQuery) ? pageNoQuery : undefined);
  }, [pageNoQuery]);
  useEffect(() => {
    form.setValue('parcel', isString(parcelQuery) ? parcelQuery : undefined);
  }, [searchQuery]);

  return (
    <div id='search-tool' className='bg-paper'>
      <form
        autoComplete='off'
        className='flex items-center space-x-[16px] px-[16px] py-[12px]'
        onSubmit={(event) => {
          event.preventDefault();
          submit();
        }}
      >
        <FormProvider {...form}>
          <DemandTypeButton onChange={submit} />
          <SearchInput />
          <FilterList onSubmit={submit} />
        </FormProvider>
      </form>
    </div>
  );
};

export default SearchTool;
