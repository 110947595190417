import { IconClose, IconDirectionDown, IconUndo } from 'components/icons';
import { useRealEstateConfigurationContext } from 'components/providers/real-estate-configuration-provider';
import { RangeValue } from 'graphql/main/queries';
import { useTranslation } from 'hooks';
import { find, gt, isEqual, isNull, isNumber, isUndefined, map, toString } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { numberToStringWithCommas, stringWithCommasToNumber } from 'utils';

interface Props {
  showing?: boolean;
  value?: RangeValue;
  onToggle?: (popover: string) => void;
  onChange?: (value?: RangeValue) => void;
}

const WidthPopover = ({ showing, value, onToggle, onChange }: Props) => {
  const translation = useTranslation();
  const { data: realEstateConfiguration } = useRealEstateConfigurationContext();
  const options = realEstateConfiguration?.common?.width;
  const [privateKeyValue, setPrivateKeyValue] = useState(value?.key);
  const [privateFromValue, setPrivateFromValue] = useState(value?.from);
  const [privateToValue, setPrivateToValue] = useState(value?.to);
  const hasFrom = isNumber(privateFromValue) && !isEqual(privateFromValue, 0);
  const hasTo = isNumber(privateToValue) && !isUndefined(privateToValue);
  const isReverse = gt(privateFromValue, privateToValue);
  const label = privateKeyValue
    ? (translation.major.width as any)[privateKeyValue]
    : hasFrom && hasTo
    ? isReverse
      ? `${privateToValue.toLocaleString()} - ${privateFromValue.toLocaleString()} m`
      : `${privateFromValue.toLocaleString()} - ${privateToValue.toLocaleString()} m`
    : hasFrom
    ? `${privateFromValue.toLocaleString()} m trở lên`
    : hasTo
    ? `${privateToValue.toLocaleString()} m trở xuống`
    : undefined;

  const togglePopover = () => {
    onToggle?.('width');
  };
  const changeFrom = (event: ChangeEvent<HTMLInputElement>) => {
    const fromValue = stringWithCommasToNumber(event.target.value);
    const foundWidth = find(
      options,
      (option) => isEqual(option?.from, fromValue) && isEqual(option.to, privateToValue),
    );
    onChange?.({
      ...value,
      key: foundWidth ? foundWidth.key : undefined,
      from: fromValue,
    });
  };
  const clearFrom = () => {
    const foundWidth = find(
      options,
      (option) => isEqual(option.from, 0) && isEqual(option.to, privateToValue),
    );
    onChange?.({
      ...value,
      key: foundWidth ? foundWidth.key : undefined,
      from: 0,
    });
  };
  const changeTo = (event: ChangeEvent<HTMLInputElement>) => {
    const toValue = stringWithCommasToNumber(event.target.value);
    const foundWidth = find(
      options,
      (option) => isEqual(option.to, toValue) && isEqual(option.from, privateFromValue),
    );
    onChange?.({
      ...value,
      key: foundWidth ? foundWidth.key : undefined,
      to: toValue,
    });
  };
  const clearTo = () => {
    const foundWidth = find(
      options,
      (option) => isNull(option.to) && isEqual(option.from, privateFromValue),
    );
    onChange?.({
      ...value,
      key: foundWidth ? foundWidth.key : undefined,
      to: null,
    });
  };
  const select = (_value: RangeValue) => {
    onToggle?.('width');
    if (!isEqual(value, _value)) {
      onChange?.(_value);
    }
  };
  const reset = () => {
    if (!isUndefined(value)) {
      onChange?.(undefined);
    }
  };
  const confirm = () => {
    onToggle?.('width');
    if (
      !isEqual(value?.key, privateKeyValue) &&
      !isEqual(value?.from, privateFromValue) &&
      !isEqual(value?.to, privateToValue)
    ) {
      onChange?.({ key: privateKeyValue, from: privateFromValue, to: privateToValue });
    }
  };

  useEffect(() => {
    setPrivateKeyValue(value?.key);
  }, [value?.key]);
  useEffect(() => {
    setPrivateFromValue(value?.from);
  }, [value?.from]);
  useEffect(() => {
    setPrivateToValue(value?.to);
  }, [value?.to]);
  useEffect(() => {
    setPrivateKeyValue(value?.key);
    setPrivateFromValue(value?.from);
    setPrivateToValue(value?.to);
  }, [showing]);

  return (
    <div id='width-popover' className='relative flex w-full min-w-0 flex-col space-y-[8px]'>
      <button
        type='button'
        className={`flex h-[40px] items-center rounded-[8px] border bg-paper p-[8px] transition-all duration-[200ms] ease-in-out disabled:cursor-not-allowed disabled:opacity-50 ${
          showing ? 'border-primary' : 'border-stroke'
        }`}
        onClick={togglePopover}
      >
        <span className={`w-full truncate text-left ${label ? '' : 'text-text2'}`}>
          {label ?? 'Ngang'}
        </span>
        <div className='broder-stroke h-full border-l pl-[8px]'>
          <IconDirectionDown className='min-h-[24px] min-w-[24px] text-text2' />
        </div>
      </button>
      {showing && (
        <div className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[4px]'>
          <div className='w-[312px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
            <div className='flex items-center space-x-[8px] px-[16px]'>
              <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                <NumericFormat
                  thousandSeparator
                  autoComplete='off'
                  placeholder='Từ'
                  maxLength={6}
                  value={numberToStringWithCommas(privateFromValue)}
                  className='w-full bg-transparent p-[12px] placeholder-text2'
                  onChange={changeFrom}
                />
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={clearFrom}
                >
                  <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                </button>
              </div>
              <span>-</span>
              <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                <NumericFormat
                  thousandSeparator
                  autoComplete='off'
                  placeholder='Đến'
                  maxLength={6}
                  value={numberToStringWithCommas(privateToValue)}
                  className='w-full bg-transparent p-[12px] placeholder-text2'
                  onChange={changeTo}
                />
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={clearTo}
                >
                  <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                </button>
              </div>
            </div>
            <ul className='flex max-h-[120px] tall-860:max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
              {map(options, (width, widthIndex) => (
                <li key={`${width}-${widthIndex}`}>
                  <button
                    type='button'
                    className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={() => {
                      select(width);
                    }}
                  >
                    <div
                      className={`min-h-[20px] min-w-[20px] rounded-full border ${
                        isEqual(privateFromValue, width.from) && isEqual(privateToValue, width.to)
                          ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                          : 'border-stroke'
                      }`}
                    />
                    <span className='line-clamp-1'>
                      {(translation.major.width as any)[toString(width.key)]}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
            <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
              <button
                type='button'
                className='flex items-center justify-center space-x-[6px]'
                onClick={reset}
              >
                <IconUndo className='text-text2' />
                <span>Đặt lại</span>
              </button>
              <button
                type='button'
                className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                onClick={confirm}
              >
                <span>Xác nhận</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WidthPopover;
