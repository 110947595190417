import {
  IconBuilding,
  IconBuildingWarehouse,
  IconChartArea,
  IconHouseDoor,
  IconHouseHeart,
  IconOfficeBuildingMarkerOutline,
  IconTextAa,
  IconVilla,
} from 'components/icons';
import { Suggestion, TypeOfDemand } from 'graphql/map/queries';
import { groupBy, isEmpty, isEqual, map, toString } from 'lodash';

interface Props {
  showing?: boolean;
  keyword?: string;
  suggestions?: Suggestion[];
}

const SuggestionPanel = ({ showing, keyword, suggestions }: Props) => {
  const groupedSuggestion = groupBy(suggestions, (suggestion) => suggestion.typeOfDemand);
  const icons = {
    townhouse: <IconHouseDoor className='min-h-[24px] min-w-[24px]' />,
    land: <IconChartArea className='min-h-[24px] min-w-[24px]' />,
    villa: <IconVilla className='min-h-[24px] min-w-[24px]' />,
    building: <IconOfficeBuildingMarkerOutline className='min-h-[24px] min-w-[24px]' />,
    hotel: <IconHouseHeart className='min-h-[24px] min-w-[24px]' />,
    warehouse: <IconBuildingWarehouse className='min-h-[24px] min-w-[24px]' />,
    apartment: <IconBuilding className='min-h-[24px] min-w-[24px]' />,
    shophouse: <IconBuilding className='min-h-[24px] min-w-[24px]' />,
    penthouse: <IconBuilding className='min-h-[24px] min-w-[24px]' />,
    condotel: <IconBuilding className='min-h-[24px] min-w-[24px]' />,
    motel: <IconHouseDoor className='min-h-[24px] min-w-[24px]' />,
  };
  const sections = [
    {
      key: TypeOfDemand.ForSale,
      label: 'Đang bán',
    },
    {
      key: TypeOfDemand.ForRent,
      label: 'Cho thuê gợi ý',
    },
  ];

  return showing ? (
    <div
      id='suggestion-panel'
      className='absolute top-full z-[2] mt-[8px] max-h-[300px] w-full overflow-y-auto bg-paper shadow-4'
    >
      {keyword && (
        <div className='flex flex-col border-y border-background'>
          <span className='min-h-[48px] cursor-default px-[12px] py-[16px] text-left text-[16px] font-[600] leading-[20px] text-text2'>
            Tìm kiếm theo từ khoá
          </span>
          <button
            type='button'
            className='flex min-h-[48px] w-full items-center space-x-[12px] px-[12px] py-[16px] text-left transition duration-[200ms] ease-in-out hover:bg-secondary'
          >
            <IconTextAa className='min-h-[24px] min-w-[24px]' />
            <span>{keyword}</span>
          </button>
        </div>
      )}
      {map(
        sections,
        (section, sectionIndex) =>
          !isEmpty(groupedSuggestion[section.key]) && (
            <div key={sectionIndex} className='flex flex-col border-y border-background'>
              <span className='min-h-[48px] cursor-default px-[12px] py-[16px] text-left text-[16px] font-[600] leading-[20px] text-text2'>
                {section.label}
              </span>
              <ul className='flex flex-col'>
                {map(groupedSuggestion[section.key], (suggestion, suggestionIndex) => (
                  <li key={suggestionIndex}>
                    {!isEqual(suggestionIndex, 0) && (
                      <div className='h-[1px] w-full bg-background' />
                    )}
                    <button
                      type='button'
                      className='flex min-h-[48px] w-full items-center space-x-[12px] px-[12px] py-[16px] text-left transition duration-[200ms] ease-in-out hover:bg-secondary'
                    >
                      {(icons as any)[toString(suggestion.realEstateType)]}
                      <span>{suggestion.fullText}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ),
      )}
    </div>
  ) : null;
};

export default SuggestionPanel;
