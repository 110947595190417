import { Popover } from '@headlessui/react';
import BedroomCountPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/bedroom-count-popover';
import DirectionPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/direction-popover';
import FloorCountPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/floor-count-popover';
import HasAlleyPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/has-alley-popover';
import LengthPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/length-popover';
import PostTypePopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/post-type-popover';
import ToiletCountPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/toilet-count-popover';
import WidthPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/width-popover';
import { IconClose, IconFilter, IconUndo } from 'components/icons';
import { SearchForm1 } from 'forms';
import { filter, isEmpty, isEqual, isUndefined, pick, size, values } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PostSourcePopover from './post-source-popover';

interface Props {
  onSubmit?: () => void;
}

const MoreFilterPopover = ({ onSubmit }: Props) => {
  const [currentPopover, setCurrentPopover] = useState<string | undefined>();
  const isHasAlleyPopoverShowing = isEqual(currentPopover, 'hasAlley');
  const isDirectionPopoverShowing = isEqual(currentPopover, 'direction');
  const isBedroomCountPopoverShowing = isEqual(currentPopover, 'bedroomCount');
  const isToiletCountPopoverShowing = isEqual(currentPopover, 'toiletCount');
  const isFloorCountPopoverShowing = isEqual(currentPopover, 'floorCount');
  const isPostTypePopoverShowing = isEqual(currentPopover, 'postType');
  const isPostSourcePopoverShowing = isEqual(currentPopover, 'postSource');
  const isWidthPopoverShowing = isEqual(currentPopover, 'width');
  const isLengthPopoverShowing = isEqual(currentPopover, 'length');
  const form = useFormContext<SearchForm1>();
  const hasAlleyWatch = useWatch({ control: form.control, name: 'hasAlley' });
  const widthWatch = useWatch({ control: form.control, name: 'width' });
  const lengthWatch = useWatch({ control: form.control, name: 'length' });
  const directionWatch = useWatch({ control: form.control, name: 'direction' });
  const bedroomCountWatch = useWatch({ control: form.control, name: 'bedroomCount' });
  const toiletCountWatch = useWatch({ control: form.control, name: 'toiletCount' });
  const floorCountWatch = useWatch({ control: form.control, name: 'floorCount' });
  const postTypeWatch = useWatch({ control: form.control, name: 'postType' });
  const postSourceWatch = useWatch({ control: form.control, name: 'postSource' });
  const pageNoWatch = useWatch({ control: form.control, name: 'pageNo' });
  const parcelWatch = useWatch({ control: form.control, name: 'parcel' });
  const [privateHasAlleyValue, setPrivateHasAlleyValue] = useState(hasAlleyWatch);
  const [privateWidthValue, setPrivateWidthValue] = useState(widthWatch);
  const [privateLengthValue, setPrivateLengthValue] = useState(lengthWatch);
  const [privateDirectionValue, setPrivateDirectionValue] = useState(directionWatch);
  const [privateBedroomCountValue, setPrivateBedroomCountValue] = useState(bedroomCountWatch);
  const [privateToiletCountValue, setPrivateToiletCountValue] = useState(toiletCountWatch);
  const [privateFloorCountValue, setPrivateFloorCountValue] = useState(floorCountWatch);
  const [privatePostTypeValue, setPrivatePostTypeValue] = useState(postTypeWatch);
  const [privatePostSourceValue, setPrivatePostSourceValue] = useState(postSourceWatch);
  const [privatePageNoValue, setPrivatePageNoValue] = useState(postSourceWatch);
  const [privateParcelValue, setPrivateParcelValue] = useState(postSourceWatch);
  const compactedFilterValues = filter(
    values(
      pick(
        form.getValues(),
        'hasAlley',
        'width',
        'length',
        'direction',
        'bedroomCount',
        'toiletCount',
        'floorCount',
        'postType',
        'postSource',
        'pageNo',
        'parcel',
      ),
    ),
    (value) => !isUndefined(value) && !isEmpty(value),
  );
  const isShowFilterBadge = !isEmpty(compactedFilterValues);

  const reset = () => {
    if (
      !isUndefined(privateHasAlleyValue) ||
      !isUndefined(privateWidthValue) ||
      !isUndefined(privateLengthValue) ||
      !isUndefined(privateDirectionValue) ||
      !isUndefined(privateBedroomCountValue) ||
      !isUndefined(privateToiletCountValue) ||
      !isUndefined(privateFloorCountValue) ||
      !isUndefined(privatePostTypeValue) ||
      !isUndefined(privatePostSourceValue) ||
      !isEmpty(privatePageNoValue) ||
      !isEmpty(privateParcelValue)
    ) {
      setPrivateHasAlleyValue(undefined);
      setPrivateWidthValue(undefined);
      setPrivateLengthValue(undefined);
      setPrivateDirectionValue(undefined);
      setPrivateBedroomCountValue(undefined);
      setPrivateToiletCountValue(undefined);
      setPrivateFloorCountValue(undefined);
      setPrivatePostTypeValue(undefined);
      setPrivatePostSourceValue(undefined);
      setPrivatePageNoValue('');
      setPrivateParcelValue('');
    }
  };
  const confirm = () => {
    if (
      !isEqual(hasAlleyWatch, privateHasAlleyValue) ||
      !isEqual(widthWatch, privateWidthValue) ||
      !isEqual(lengthWatch, privateLengthValue) ||
      !isEqual(directionWatch, privateDirectionValue) ||
      !isEqual(bedroomCountWatch, privateBedroomCountValue) ||
      !isEqual(toiletCountWatch, privateToiletCountValue) ||
      !isEqual(floorCountWatch, privateFloorCountValue) ||
      !isEqual(postTypeWatch, privatePostTypeValue) ||
      !isEqual(postSourceWatch, privatePostSourceValue) ||
      !isEqual(pageNoWatch, privatePageNoValue) ||
      !isEqual(parcelWatch, privateParcelValue)
    ) {
      form.setValue('hasAlley', privateHasAlleyValue);
      form.setValue('width', privateWidthValue);
      form.setValue('length', privateLengthValue);
      form.setValue('direction', privateDirectionValue);
      form.setValue('bedroomCount', privateBedroomCountValue);
      form.setValue('toiletCount', privateToiletCountValue);
      form.setValue('floorCount', privateFloorCountValue);
      form.setValue('postType', privatePostTypeValue);
      form.setValue('postSource', privatePostSourceValue);
      form.setValue('pageNo', privatePageNoValue);
      form.setValue('parcel', privateParcelValue);
      onSubmit?.();
    }
  };

  return (
    <Popover
      id='more-filter-popover'
      className='relative flex max-w-[156px] flex-col space-y-[8px]'
    >
      {({ open, close }) => {
        useEffect(() => {
          setCurrentPopover(undefined);
          setPrivateHasAlleyValue(hasAlleyWatch);
          setPrivateWidthValue(widthWatch);
          setPrivateLengthValue(lengthWatch);
          setPrivateDirectionValue(directionWatch);
          setPrivateBedroomCountValue(bedroomCountWatch);
          setPrivateToiletCountValue(toiletCountWatch);
          setPrivateFloorCountValue(floorCountWatch);
          setPrivatePostTypeValue(postTypeWatch);
          setPrivatePostSourceValue(postSourceWatch);
          setPrivatePageNoValue(pageNoWatch);
          setPrivateParcelValue(parcelWatch);
        }, [open]);

        return (
          <Fragment>
            <Popover.Button
              type='button'
              className={`group relative flex h-full items-center space-x-[8px] transition duration-[200ms] ease-in-out hover:text-primary ${
                isShowFilterBadge || open ? 'text-primary' : ''
              }`}
            >
              {isShowFilterBadge && (
                <span className='absolute left-[16px] top-[-8px] flex h-[20px] w-[20px] items-center justify-center rounded-full border border-paper bg-primary text-[10px] text-paper'>
                  {size(compactedFilterValues)}
                </span>
              )}
              <IconFilter
                className={`min-h-[24px] min-w-[24px] duration-[200ms] group-hover:text-primary ${
                  isShowFilterBadge || open ? 'text-primary' : 'text-text2'
                }`}
              />
              <span className='hidden truncate xl:inline'>Lọc thêm</span>
            </Popover.Button>
            <Popover.Panel className='absolute right-[-16px] top-full z-[2] pt-[16px]'>
              <div className='space-y-[16px] rounded-[8px] bg-paper shadow-4'>
                <div className='flex items-center justify-center border-b border-stroke p-[16px]'>
                  <span className='text-[16px] font-[600] leading-[25px]'>Lọc thêm</span>
                </div>
                <div className='flex space-x-[16px] px-[16px]'>
                  <div className='flex w-[248px] flex-col space-y-[16px]'>
                    <HasAlleyPopover
                      showing={isHasAlleyPopoverShowing}
                      value={privateHasAlleyValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivateHasAlleyValue(value)}
                    />
                    <WidthPopover
                      showing={isWidthPopoverShowing}
                      value={privateWidthValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivateWidthValue(value)}
                    />
                    <LengthPopover
                      showing={isLengthPopoverShowing}
                      value={privateLengthValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivateLengthValue(value)}
                    />
                  </div>
                  <div className='flex w-[248px] flex-col space-y-[16px]'>
                    <DirectionPopover
                      showing={isDirectionPopoverShowing}
                      value={privateDirectionValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivateDirectionValue(value)}
                    />
                    <BedroomCountPopover
                      showing={isBedroomCountPopoverShowing}
                      value={privateBedroomCountValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivateBedroomCountValue(value)}
                    />
                    <ToiletCountPopover
                      showing={isToiletCountPopoverShowing}
                      value={privateToiletCountValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivateToiletCountValue(value)}
                    />
                    <FloorCountPopover
                      showing={isFloorCountPopoverShowing}
                      value={privateFloorCountValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivateFloorCountValue(value)}
                    />
                  </div>
                  <div className='flex w-[248px] flex-col space-y-[16px]'>
                    <PostSourcePopover
                      showing={isPostSourcePopoverShowing}
                      value={privatePostSourceValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivatePostSourceValue(value)}
                    />
                    <PostTypePopover
                      showing={isPostTypePopoverShowing}
                      value={privatePostTypeValue}
                      onToggle={(popover: string) =>
                        setCurrentPopover(currentPopover ? undefined : popover)
                      }
                      onChange={(value) => setPrivatePostTypeValue(value)}
                    />
                    <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                      <input
                        type='text'
                        value={privatePageNoValue}
                        className='w-full bg-transparent p-[8px] placeholder-text2'
                        placeholder='Số tờ'
                        onChange={(event) => setPrivatePageNoValue(event.target.value)}
                      />
                      {Boolean(pageNoWatch) && (
                        <button
                          type='button'
                          className='ml-[8px] hidden group-hover:flex'
                          onClick={() => setPrivatePageNoValue('')}
                        >
                          <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                        </button>
                      )}
                    </div>
                    <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                      <input
                        type='text'
                        value={privateParcelValue}
                        className='w-full bg-transparent p-[8px] placeholder-text2'
                        placeholder='Số thửa'
                        onChange={(event) => setPrivateParcelValue(event.target.value)}
                      />
                      {Boolean(parcelWatch) && (
                        <button
                          type='button'
                          className='ml-[8px] hidden group-hover:flex'
                          onClick={() => setPrivateParcelValue('')}
                        >
                          <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-between border-t border-stroke px-[16px] py-[12px]'>
                  <button
                    type='button'
                    className='flex items-center justify-center space-x-[6px]'
                    onClick={reset}
                  >
                    <IconUndo className='text-text2' />
                    <span>Đặt lại</span>
                  </button>
                  <button
                    type='button'
                    className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                    onClick={() => {
                      confirm();
                      close();
                    }}
                  >
                    <span>Xác nhận</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Fragment>
        );
      }}
    </Popover>
  );
};

export default MoreFilterPopover;
