import { IconClose } from 'components/icons';
import { isEmpty } from 'lodash';
import { MouseEvent, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  closable?: boolean;
  title?: string;
  className?: string;
  onClose?: () => void;
}

const Modal = ({ closable, title, children, className, onClose }: Props) => {
  const clickWrapper = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const closeModal = () => {
    onClose?.();
  };

  return (
    <div
      className={`flex flex-col space-y-[28px] rounded-[16px] bg-paper p-[24px] shadow-4 ${
        className ?? ''
      }`}
      onClick={clickWrapper}
    >
      {(!isEmpty(title) || closable) && (
        <div className='relative flex items-center justify-center py-[3px] px-[24px]'>
          {!isEmpty(title) && (
            <span className='text-center text-[18px] font-[600] leading-[23px]'>{title}</span>
          )}
          {closable && (
            <button
              type='button'
              className='absolute top-1/2 right-0 -translate-y-1/2'
              onClick={closeModal}
            >
              <IconClose className='min-h-[24px] min-w-[24px] text-text2' />
            </button>
          )}
        </div>
      )}
      <div className='h-full'>{children}</div>
    </div>
  );
};

export default Modal;
