import MainContent from 'components/desktop/search-result/main-section/main-content';
import SeoContent from 'components/desktop/search-result/main-section/seo-content';

const MainSection = () => (
  <div
    id='main-section'
    className='container mx-auto min-w-[1052px] px-[54px] screen-1140:px-[98px] xl:px-[48px] screen-1366:px-[83px]'
  >
    <div className='flex flex-col space-y-[28px] rounded-[16px] bg-paper p-[24px] shadow-1'>
      <MainContent />
      <SeoContent />
      {/* <TagList /> */}
    </div>
  </div>
);

export default MainSection;
