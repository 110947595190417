import { SearchForm1 } from 'forms';
import { useTranslation } from 'hooks';
import { isEqual, map } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
  onChange?: () => void;
}

const DemandTypeButton = ({ onChange }: Props) => {
  const translation = useTranslation();
  const form = useFormContext<SearchForm1>();
  const demandTypeWatch = useWatch({ control: form.control, name: 'demandType' });
  const options = ['sell', 'rent'];

  const translate = (key?: string) => (key ? (translation.major.demand as any)[key] : undefined);
  const change = (value: string) => {
    form.setValue('demandType', value);
    form.setValue('type', undefined);
    form.setValue('price', undefined);
    onChange?.();
  };

  return (
    <div id='demand-type-button' className='flex space-x-[16px]'>
      {map(options, (option, optionIndex) => (
        <button
          key={optionIndex}
          type='button'
          disabled={isEqual(demandTypeWatch, option)}
          className={`flex h-[36px] items-center justify-center rounded-[8px] p-[8px] transition duration-[200ms] ease-in-out ${
            isEqual(demandTypeWatch, option)
              ? 'border border-primary bg-secondary'
              : 'bg-tertiary hover:bg-tertiary-light'
          }`}
          onClick={() => {
            change(option);
          }}
        >
          <span
            className={`${
              isEqual(demandTypeWatch, option) ? 'font-[600] text-primary' : ''
            } whitespace-nowrap`}
          >
            {translate(option)}
          </span>
        </button>
      ))}
    </div>
  );
};

export default DemandTypeButton;
