import { Popover } from '@headlessui/react';
import { IconClose, IconDirectionDown, IconUndo } from 'components/icons';
import { useRealEstateConfigurationContext } from 'components/providers/real-estate-configuration-provider';
import { SearchForm1 } from 'forms';
import { RealEstateTypeEnum } from 'graphql/main/queries';
import { useTranslation } from 'hooks';
import { filter, includes, isEmpty, isEqual, isUndefined, map } from 'lodash';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { removeVietnameseAccents } from 'utils';

interface Props {
  onSubmit?: () => void;
}

const TypePopover = ({ onSubmit }: Props) => {
  const translation = useTranslation();
  const [search, setSearch] = useState('');
  const form = useFormContext<SearchForm1>();
  const { data: realEstateConfiguration } = useRealEstateConfigurationContext();
  const demandTypeWatch = useWatch({
    control: form.control,
    name: 'demandType',
  });
  const typeWatch = useWatch({
    control: form.control,
    name: 'type',
  });
  const options = map(
    (isEqual(demandTypeWatch, 'rent')
      ? realEstateConfiguration?.common?.forRentRealEstateTypes
      : realEstateConfiguration?.common?.forSellRealEstateTypes) as any,
    (realEstateType) => realEstateType.key,
  );
  const [privateOptions, setPrivateOptions] = useState(options);

  const translate = (key?: string) =>
    key
      ? (
          (isEqual(demandTypeWatch, 'rent')
            ? translation.major.forRentRealEstateType
            : translation.major.forSellRealEstateType) as any
        )[key]
      : undefined;

  const label = isEmpty(typeWatch) ? undefined : translate(typeWatch?.[0]);

  const changeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const clearSearch = () => {
    setSearch('');
  };
  const select = (value: RealEstateTypeEnum) => {
    setSearch('');
    if (!isEqual(typeWatch, [value])) {
      form.setValue('type', [value]);
      onSubmit?.();
    }
  };
  const reset = () => {
    setSearch('');
    if (!isUndefined(typeWatch)) {
      form.setValue('type', undefined);
      onSubmit?.();
    }
  };

  useEffect(() => {
    setPrivateOptions(
      filter(options, (option) =>
        includes(removeVietnameseAccents(translate(option)), removeVietnameseAccents(search)),
      ),
    );
  }, [search, demandTypeWatch]);
  useEffect(() => {
    setPrivateOptions(options);
  }, [realEstateConfiguration]);

  return (
    <Popover
      id='type-popover'
      className='relative flex min-w-0 max-w-[156px] flex-col space-y-[8px]'
    >
      {({ open, close }) => {
        useEffect(() => {
          setSearch('');
        }, [open]);

        return (
          <Fragment>
            <Popover.Button
              type='button'
              className={`group flex h-full items-center justify-center space-x-[8px] transition duration-[200ms] ease-in-out hover:text-primary ${
                open ? 'text-primary' : ''
              }`}
            >
              <span className='truncate text-left text-[14px]'>{label ?? 'Loại BĐS'}</span>
              <IconDirectionDown
                className={`min-h-[24px] min-w-[24px] duration-[200ms] group-hover:text-primary ${
                  open ? 'text-primary' : 'text-text2'
                }`}
              />
            </Popover.Button>
            <Popover.Panel className='absolute left-1/2 top-full z-[2] -translate-x-1/2 pt-[4px]'>
              <div className='w-[312px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
                <div className='group mx-[16px] flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                  <input
                    type='text'
                    className='w-full bg-transparent p-[12px] placeholder-text2'
                    placeholder='Nhập từ khoá tìm kiếm'
                    value={search}
                    onChange={changeSearch}
                  />
                  {Boolean(search) && (
                    <button
                      type='button'
                      className='ml-[8px] hidden group-hover:flex'
                      onClick={clearSearch}
                    >
                      <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                    </button>
                  )}
                </div>
                {isEmpty(privateOptions) ? (
                  <span className='block h-full px-[16px] italic text-text2'>
                    Không có tuỳ chọn
                  </span>
                ) : (
                  <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
                    {map(privateOptions, (option, optionIndex) => (
                      <li key={`${option}-${optionIndex}`}>
                        <button
                          type='button'
                          className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                          onClick={() => {
                            select(option);
                            close();
                          }}
                        >
                          <div
                            className={`min-h-[20px] min-w-[20px] rounded-full border ${
                              isEqual(typeWatch?.[0], option)
                                ? 'relative border-primary before:absolute before:bottom-[2px] before:left-[2px] before:right-[2px] before:top-[2px] before:rounded-full before:bg-primary before:content-[""]'
                                : 'border-stroke'
                            }`}
                          />
                          <span className='line-clamp-1'>{translate(option)}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
                  <button
                    type='button'
                    className='flex items-center justify-center space-x-[6px]'
                    onClick={() => {
                      reset();
                      close();
                    }}
                  >
                    <IconUndo className='text-text2' />
                    <span>Đặt lại</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Fragment>
        );
      }}
    </Popover>
  );
};

export default TypePopover;
