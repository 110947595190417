import Breadcrumb from 'components/desktop/shared/breadcrumb';
import FormSelect from 'components/desktop/shared/form-select';
import Pagination1 from 'components/desktop/shared/pagination1';
import RealEstateHorizontalCard from 'components/desktop/shared/real-estate-horizontal-card';
import RealEstateVerticalCard from 'components/desktop/shared/real-estate-vertical-card';
import { IconGrid, IconList } from 'components/icons';
import { SortForm, realEstateSortOptions } from 'forms';
import { SITE_MAP } from 'globalConstants';
import { isEmpty, isEqual, isString, map, size, toString } from 'lodash';
import { useRouter } from 'next/router';
import { SearchResultPageContext } from 'pages/[ket-qua-tim-kiem]';
import { Fragment, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const statuses = [
  {
    name: 'list',
    icon: <IconList className='h-[20px] w-[20px]' />,
  },
  {
    name: 'grid',
    icon: <IconGrid className='h-[20px] w-[20px]' />,
  },
];

const MainContent = () => {
  const { slugify, realEstatesWithPagination } = useContext(SearchResultPageContext).pageData ?? {};
  const { items: realEstates, ...pagination } = realEstatesWithPagination ?? {};
  const { breadScrumbs, title } = slugify ?? {};
  const router = useRouter();
  const { query, push } = router;
  const {
    [SITE_MAP.SEARCH_RESULT.INDEX]: searchResultPathname,
    page: pageQuery,
    sort: sortQuery,
    ...restRouterQueries
  } = query;
  const form = useForm<SortForm>({
    defaultValues: {
      sort: isString(sortQuery) ? sortQuery : '-createdAt',
    },
  });
  const [viewStatus, setViewStatus] = useState<string | null>('list');

  const changeViewStatus = (status: string) => {
    setViewStatus(status);
  };
  const sort = (key?: string) => {
    push({
      pathname: toString(searchResultPathname),
      query: isEmpty(key)
        ? restRouterQueries
        : {
            ...restRouterQueries,
            sort: key,
          },
    });
  };
  const changePage = (newPage: number) => {
    push({
      pathname: toString(searchResultPathname),
      query: {
        ...restRouterQueries,
        ...(isString(sortQuery) && !isEmpty(sortQuery)
          ? { sort: sortQuery, page: newPage }
          : { page: newPage }),
      },
    });
  };

  return (
    <div className='flex flex-col space-y-[28px]'>
      <div className='flex flex-col space-y-[24px]'>
        <div className='w-full space-y-[24px]'>
          <Breadcrumb
            breadcrumbs={
              isEqual(size(breadScrumbs), 1)
                ? breadScrumbs
                : [{ name: 'Trang chủ', slug: '' }, ...(breadScrumbs ?? [])]
            }
          />
          <h1 className='text-[24px] font-[600] leading-[36px]'>{title}</h1>
        </div>
        <div className='flex items-center justify-between bg-background px-[16px] py-[15px]'>
          <div className='flex items-center space-x-[24px]'>
            {map(statuses, (status, statusIndex) => (
              <button
                key={statusIndex}
                type='button'
                className={isEqual(viewStatus, status.name) ? '' : 'text-placeholder'}
                onClick={() => {
                  changeViewStatus(status.name);
                }}
              >
                {status.icon}
              </button>
            ))}
          </div>
          <form>
            <FormProvider {...form}>
              <div className='flex items-center space-x-[16px]'>
                <span>
                  Có{' '}
                  {
                    <span className='font-[600] text-primary'>
                      {' '}
                      {size(realEstates) ? pagination.total : 0}{' '}
                    </span>
                  }
                  kết quả được tìm thấy
                </span>
                <FormSelect
                  placeHolder
                  name='sort'
                  label='Sắp xếp'
                  options={realEstateSortOptions}
                  emptyOptionsText='Không có tuỳ chọn'
                  wrapperClassName='h-[36px] bg-paper px-[12px] py-[9px] w-[200px]'
                  optionClassName='h-[32px] px-[12px]'
                  popoverClassName='py-[9px]'
                  onChange={sort}
                />
              </div>
            </FormProvider>
          </form>
        </div>
      </div>
      <Fragment>
        {isEqual(viewStatus, 'grid') ? (
          <div className='m-[-8px] flex flex-wrap'>
            {isEmpty(realEstates) ? (
              <div className='flex w-full flex-col items-center space-y-[28px]'>
                <div className='h-[200px] w-full bg-[url("/images/no-data-found.svg")] bg-contain bg-center bg-no-repeat' />
                <span className='text-text2'>Chưa có tin rao nào</span>
              </div>
            ) : (
              map(realEstates, (realEstate, realEstateIndex) => (
                <div key={`${realEstate.id}-${realEstateIndex}`} className='w-1/3 p-[8px] xl:w-1/4'>
                  <RealEstateVerticalCard
                    type={
                      realEstate?.isAuthorizedRe
                        ? 'deposit'
                        : realEstate?.isForeclosure
                        ? 'foreclosure'
                        : realEstate?.isLeadRE
                        ? 'lead'
                        : isEqual(realEstate?.postType, 1)
                        ? 'special'
                        : isEqual(realEstate?.postType, 2)
                        ? 'vip'
                        : 'normal'
                    }
                    realEstate={realEstate}
                  />
                </div>
              ))
            )}
          </div>
        ) : (
          <div className='flex flex-col space-y-[28px]'>
            {isEmpty(realEstates) ? (
              <div className='flex w-full flex-col items-center space-y-[28px]'>
                <div className='h-[200px] w-full bg-[url("/images/no-data-found.svg")] bg-contain bg-center bg-no-repeat' />
                <span className='text-text2'>Chưa có tin rao nào</span>
              </div>
            ) : (
              map(realEstates, (realEstate, realEstateIndex) => (
                <Fragment key={`${realEstate.id}-${realEstateIndex}`}>
                  {!isEqual(realEstateIndex, 0) && <div className='h-[1px] w-full bg-stroke' />}
                  <RealEstateHorizontalCard
                    type={
                      realEstate?.isAuthorizedRe
                        ? 'deposit'
                        : realEstate?.isForeclosure
                        ? 'foreclosure'
                        : realEstate?.isLeadRE
                        ? 'lead'
                        : isEqual(realEstate?.postType, 1)
                        ? 'special'
                        : isEqual(realEstate?.postType, 2)
                        ? 'vip'
                        : 'normal'
                    }
                    realEstate={realEstate}
                  />
                </Fragment>
              ))
            )}
          </div>
        )}
        {!isEmpty(realEstates) && (
          <div className='flex justify-end'>
            <Pagination1
              placeholder='Nhập số trang'
              informationTexts={['Hiển thị từ', 'trong tổng số']}
              pagination={pagination}
              realPageSize={size(realEstates)}
              onChangePage={changePage}
            />
          </div>
        )}
      </Fragment>
    </div>
  );
};

export default MainContent;
