import { Popover } from '@headlessui/react';
import { IconClose, IconDirectionDown, IconUndo } from 'components/icons';
import { useRealEstateConfigurationContext } from 'components/providers/real-estate-configuration-provider';
import { SearchForm1 } from 'forms';
import { RangeValue } from 'graphql/main/queries';
import { useTranslation } from 'hooks';
import { find, isEqual, isNull, isNumber, isUndefined, map } from 'lodash';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { numberToStringWithCommas, stringWithCommasToNumber } from 'utils';

interface Props {
  onSubmit?: () => void;
}

const AreaPopover = ({ onSubmit }: Props) => {
  const translation = useTranslation();
  const form = useFormContext<SearchForm1>();
  const areaWatch = useWatch({
    control: form.control,
    name: 'area',
  });
  const { data: realEstateConfiguration } = useRealEstateConfigurationContext();
  const options = realEstateConfiguration?.common?.area;
  const [privateValue, setPrivateValue] = useState(areaWatch);
  const hasFrom = isNumber(areaWatch?.from) && !isEqual(areaWatch?.from, 0);
  const hasTo = isNumber(areaWatch?.to) && !isUndefined(areaWatch?.to);

  const translate = (key?: string) => (key ? (translation.major.area as any)[key] : undefined);

  const label = areaWatch?.key
    ? translate(areaWatch.key)
    : hasFrom && hasTo
    ? `${areaWatch?.from?.toLocaleString()} - ${areaWatch?.to?.toLocaleString()} m\u00B2`
    : hasFrom
    ? `${areaWatch?.from?.toLocaleString()} m\u00B2 trở lên`
    : hasTo
    ? `${areaWatch?.to?.toLocaleString()} m\u00B2 trở xuống`
    : undefined;

  const changeFrom = (event: ChangeEvent<HTMLInputElement>) => {
    const fromValue = stringWithCommasToNumber(event.target.value);
    const foundArea = find(
      options,
      (option) => isEqual(option.from, fromValue) && isEqual(option.to, privateValue?.to),
    );
    setPrivateValue({
      ...privateValue,
      key: foundArea ? foundArea.key : undefined,
      from: fromValue,
    });
  };
  const clearFrom = () => {
    const foundArea = find(
      options,
      (option) => isEqual(option.from, 0) && isEqual(option.to, privateValue?.to),
    );
    setPrivateValue({
      ...privateValue,
      key: foundArea ? foundArea.key : undefined,
      from: 0,
    });
  };
  const changeTo = (event: ChangeEvent<HTMLInputElement>) => {
    const toValue = stringWithCommasToNumber(event.target.value);
    const foundArea = find(
      options,
      (option) => isEqual(option.to, toValue) && isEqual(option.from, privateValue?.from),
    );
    setPrivateValue({
      ...privateValue,
      key: foundArea ? foundArea.key : undefined,
      to: toValue,
    });
  };
  const clearTo = () => {
    const foundArea = find(
      options,
      (option) => isNull(option.to) && isEqual(option.from, privateValue?.from),
    );
    setPrivateValue({
      ...privateValue,
      key: foundArea ? foundArea.key : undefined,
      to: null,
    });
  };
  const select = (value: RangeValue) => {
    if (!isEqual(value.from, areaWatch?.from) && !isEqual(value.to, areaWatch?.to)) {
      form.setValue('area', value);
      onSubmit?.();
    }
  };
  const reset = () => {
    if (!isUndefined(privateValue)) {
      setPrivateValue(undefined);
    }
  };
  const confirm = () => {
    if (
      !isEqual(privateValue?.from, areaWatch?.from) &&
      !isEqual(privateValue?.to, areaWatch?.to)
    ) {
      form.setValue('area', privateValue);
      onSubmit?.();
    }
  };

  useEffect(() => {
    setPrivateValue(areaWatch);
  }, [areaWatch]);

  return (
    <Popover
      id='area-popover'
      className='relative flex min-w-0 max-w-[156px] flex-col space-y-[8px]'
    >
      {({ open, close }) => {
        useEffect(() => {
          setPrivateValue(areaWatch);
        }, [open]);

        return (
          <Fragment>
            <Popover.Button
              type='button'
              className={`group flex h-full items-center justify-center space-x-[8px] transition duration-[200ms] ease-in-out hover:text-primary ${
                open ? 'text-primary' : ''
              }`}
            >
              <span className='truncate text-left text-[14px]'>{label ?? 'Diện tích'}</span>
              <IconDirectionDown
                className={`min-h-[24px] min-w-[24px] duration-[200ms] group-hover:text-primary ${
                  open ? 'text-primary' : 'text-text2'
                }`}
              />
            </Popover.Button>
            <Popover.Panel className='absolute left-1/2 top-full z-[2] -translate-x-1/2 pt-[4px]'>
              <div className='w-[312px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
                <div className='flex items-center space-x-[8px] px-[16px]'>
                  <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                    <NumericFormat
                      thousandSeparator
                      autoComplete='off'
                      placeholder='Từ'
                      maxLength={19}
                      value={numberToStringWithCommas(privateValue?.from)}
                      className='w-full bg-transparent p-[12px] placeholder-text2'
                      onChange={changeFrom}
                    />
                    <button
                      type='button'
                      className='ml-[8px] hidden group-hover:flex'
                      onClick={clearFrom}
                    >
                      <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                    </button>
                  </div>
                  <span>-</span>
                  <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                    <NumericFormat
                      thousandSeparator
                      autoComplete='off'
                      placeholder='Đến'
                      maxLength={19}
                      value={numberToStringWithCommas(privateValue?.to)}
                      className='w-full bg-transparent p-[12px] placeholder-text2'
                      onChange={changeTo}
                    />
                    <button
                      type='button'
                      className='ml-[8px] hidden group-hover:flex'
                      onClick={clearTo}
                    >
                      <IconClose className='min-h-[20px] min-w-[20px] text-text2' />
                    </button>
                  </div>
                </div>
                <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
                  {map(options, (area, areaIndex) => (
                    <li key={`${area}-${areaIndex}`}>
                      <button
                        type='button'
                        className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                        onClick={() => {
                          select(area);
                          close();
                        }}
                      >
                        <div
                          className={`min-h-[20px] min-w-[20px] rounded-full border ${
                            isEqual(privateValue?.from, area.from) &&
                            isEqual(privateValue?.to, area.to)
                              ? 'relative border-primary before:absolute before:bottom-[2px] before:left-[2px] before:right-[2px] before:top-[2px] before:rounded-full before:bg-primary before:content-[""]'
                              : 'border-stroke'
                          }`}
                        />
                        <span className='line-clamp-1'>{translate(area.key)}</span>
                      </button>
                    </li>
                  ))}
                </ul>
                <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
                  <button
                    type='button'
                    className='flex items-center justify-center space-x-[6px]'
                    onClick={reset}
                  >
                    <IconUndo className='text-text2' />
                    <span>Đặt lại</span>
                  </button>
                  <button
                    type='button'
                    className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                    onClick={() => {
                      confirm();
                      close();
                    }}
                  >
                    <span>Xác nhận</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Fragment>
        );
      }}
    </Popover>
  );
};

export default AreaPopover;
