import { Popover } from '@headlessui/react';
import { IconDirectionDown, IconUndo } from 'components/icons';
import { SearchForm1 } from 'forms';
import { CommonAddress } from 'graphql/main/queries';
import { compact, isEqual, join } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import DistrictPopover from './district-popover';
import ProvincePopover from './province-popover';
import StreetPopover from './street-popover';
import WardPopover from './ward-popover';

interface Props {
  onSubmit?: () => void;
}

const AddressPopover = ({ onSubmit }: Props) => {
  const [currentPopover, setCurrentPopover] = useState<string | undefined>();
  const isProvincePopoverShowing = isEqual(currentPopover, 'province');
  const isDistrictPopoverShowing = isEqual(currentPopover, 'district');
  const isWardPopoverShowing = isEqual(currentPopover, 'ward');
  const isStreetPopoverShowing = isEqual(currentPopover, 'street');
  const form = useFormContext<SearchForm1>();
  const provinceWatch = useWatch({ control: form.control, name: 'province' });
  const districtWatch = useWatch({ control: form.control, name: 'district' });
  const wardWatch = useWatch({ control: form.control, name: 'ward' });
  const streetWatch = useWatch({ control: form.control, name: 'street' });
  const [privateProvinceValue, setPrivateProvinceValue] = useState(provinceWatch);
  const [privateDistrictValue, setPrivateDistrictValue] = useState(districtWatch);
  const [privateWardValue, setPrivateWardValue] = useState(wardWatch);
  const [privateStreetValue, setPrivateStreetValue] = useState(streetWatch);
  const label =
    join(
      compact([streetWatch?.name, wardWatch?.name, districtWatch?.name, provinceWatch?.name]),
      ', ',
    ) || undefined;

  const changeProvince = (value?: CommonAddress) => {
    setPrivateProvinceValue(value);
    setPrivateDistrictValue(undefined);
    setPrivateWardValue(undefined);
    setPrivateStreetValue(undefined);
  };
  const changeDistrict = (value?: CommonAddress) => {
    setPrivateDistrictValue(value);
    setPrivateWardValue(undefined);
    setPrivateStreetValue(undefined);
  };
  const reset = () => {
    setPrivateProvinceValue(undefined);
    setPrivateDistrictValue(undefined);
    setPrivateWardValue(undefined);
    setPrivateStreetValue(undefined);
  };
  const confirm = () => {
    if (
      !isEqual(provinceWatch, privateProvinceValue) ||
      !isEqual(districtWatch, privateDistrictValue) ||
      !isEqual(wardWatch, privateWardValue) ||
      !isEqual(streetWatch, privateStreetValue)
    ) {
      form.setValue('province', privateProvinceValue);
      form.setValue('district', privateDistrictValue);
      form.setValue('ward', privateWardValue);
      form.setValue('street', privateStreetValue);
      onSubmit?.();
    }
  };

  return (
    <Popover id='address-popover' className='relative flex max-w-[156px] flex-col space-y-[8px]'>
      {({ open, close }) => {
        useEffect(() => {
          setCurrentPopover(undefined);
          setPrivateProvinceValue(provinceWatch);
          setPrivateDistrictValue(districtWatch);
          setPrivateWardValue(wardWatch);
          setPrivateStreetValue(streetWatch);
        }, [open]);

        return (
          <Fragment>
            <Popover.Button
              type='button'
              className={`group flex h-full items-center space-x-[8px] overflow-x-hidden transition duration-[200ms] ease-in-out hover:text-primary ${
                open ? 'text-primary' : ''
              }`}
            >
              <span className='truncate text-left text-[14px]'>{label ?? 'Khu vực BĐS'}</span>
              <IconDirectionDown
                className={`min-h-[24px] min-w-[24px] duration-[200ms] group-hover:text-primary ${
                  open ? 'text-primary' : 'text-text2'
                }`}
              />
            </Popover.Button>
            <Popover.Panel className='absolute left-1/2 top-full z-[2] -translate-x-1/2 pt-[16px]'>
              <div className='w-[280px] space-y-[16px] rounded-[8px] bg-paper shadow-4'>
                <div className='flex items-center justify-center border-b border-stroke p-[16px]'>
                  <span className='text-[16px] font-[600] leading-[25px]'>Khu vực BĐS</span>
                </div>
                <div className='flex flex-col space-y-[16px] px-[16px]'>
                  <ProvincePopover
                    showing={isProvincePopoverShowing}
                    value={privateProvinceValue}
                    onToggle={(popover) => setCurrentPopover(currentPopover ? undefined : popover)}
                    onChange={changeProvince}
                  />
                  <DistrictPopover
                    showing={isDistrictPopoverShowing}
                    value={privateDistrictValue}
                    province={privateProvinceValue}
                    onToggle={(popover) => setCurrentPopover(currentPopover ? undefined : popover)}
                    onChange={changeDistrict}
                  />
                  <WardPopover
                    showing={isWardPopoverShowing}
                    value={privateWardValue}
                    district={privateDistrictValue}
                    onToggle={(popover) => setCurrentPopover(currentPopover ? undefined : popover)}
                    onChange={(value) => setPrivateWardValue(value)}
                  />
                  <StreetPopover
                    showing={isStreetPopoverShowing}
                    value={privateStreetValue}
                    district={privateDistrictValue}
                    onToggle={(popover) => setCurrentPopover(currentPopover ? undefined : popover)}
                    onChange={(value) => setPrivateStreetValue(value)}
                  />
                </div>
                <div className='flex items-center justify-between border-t border-stroke p-[16px] py-[12px]'>
                  <button
                    type='button'
                    className='flex items-center justify-center space-x-[6px]'
                    onClick={reset}
                  >
                    <IconUndo className='text-text2' />
                    <span>Đặt lại</span>
                  </button>
                  <button
                    type='button'
                    className='flex h-[40px] items-center justify-center rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                    onClick={() => {
                      confirm();
                      close();
                    }}
                  >
                    <span>Xác nhận</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Fragment>
        );
      }}
    </Popover>
  );
};

export default AddressPopover;
